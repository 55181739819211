body{
	direction: rtl;
}

.sidebar {
	left: auto;
	right: 0;
}

.main-panel{
	float:left;
}

.sidebar .nav li.active > a:before, .off-canvas-sidebar .nav li.active > a:before{
	border-right: 0;
	border-left: 17px solid rgb(221, 221, 221);

	right: auto;
	left: 0;
}

.sidebar .nav li.active > a:after, .off-canvas-sidebar .nav li.active > a:after{
	border-right: 0;
	border-left: 17px solid #f4f3ef;

	right: auto;
	left: -1px;
}

.nav{
	padding-right: 0;
}

.sidebar .nav i, .off-canvas-sidebar .nav i{
	float: right;
	margin-right: 0;
	margin-left: 15px;
}

[class^="ti-"], [class*=" ti-"]{
	font-weight: normal;
}

@media screen and (min-width: 768px){
	.navbar-header {
	    float: right;
	}
	.navbar-nav > li{
		float: right;
	}
}

.reverse{
    float: left;
}