
// Fonts
@import url(https://fonts.googleapis.com/css?family=Changa);

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "bootstrap-grid";

// paper dashboard
@import "paper/paper-dashboard";
@import "paper/paper-rtl";



.lock-wrapper {
    margin:3% auto;
    max-width:500px;
}
.lock-box {
    position:relative;
}
.lock-name {
    background:#fff;
    width:50%;
    float:left;
    height:50px;
    line-height:50px;
    margin-top:55px;
    padding:0 90px 0 20px;
    text-align:center;
    border-radius:5px 0 0 5px;
    -webkit-border-radius:5px 0 0 5px;
    color:#000;
    font-size: 16px;

    font-family: $font-family;
}
.lock-pwd {
    background:#fff;
    width:50%;
    float:right;
    height:50px;
    line-height:50px;
    margin-top:55px;
    padding:0 0 0 80px;
    border-radius:0 5px 5px 0;
    -webkit-border-radius:0 5px 5px 0;
    color:#000;
    font-size: 16px;
    font-family: $font-family;
}
.lock-wrapper img {
    position:absolute;
    left:33%;
    border-radius:50%;
    -webkit-border-radius:50%;
    border:10px solid #fff;
    width:160px;
    height:160px;
    background: #fff;
}
#time {
    width:100%;
    color:#32323A;
    font-size:125px;
    display:inline-block;
    text-align:center;
    font-weight: 100;
}

.popover{
    width: 276px;
}

.form-control{
    background: #fff;
    border: 1px solid #ccc;
    font-family: $font-family;
}
.card label, .control-label, .btn{
    font-family: $font-family;
}
body.rtl{
    .btn{
        font-family: $font-family;
    }
    .datepicker-dropdown{
        opacity: 1;
        visibility: visible;
    }
    .form-control{
        direction: rtl;
    }
    .select2{
        direction: rtl;
        font-family: $font-family;
        height: 40px;
    }
    .select2-container--bootstrap .select2-selection--single{
        height: 40px;
        line-height: 25px;
    }
    .select2-container--bootstrap .select2-selection--single .select2-selection__arrow{
        height: 40px;
        right: auto;
        left: 12px;
    }
}

.text-center{
    text-align: center;
}

.no-radius-left{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.no-radius-right{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.no-radius{
    border-radius: 0;
}
.auth-body{
    background-color: #66615b;
}

[class^="ti-"], [class*=" ti-"]{
    font-weight: normal !important;
}

.sk-cube-grid {
  width: 40px;
  height: 40px;
  margin: 100px auto;
}

.sk-cube-grid .sk-cube {
  width: 33%;
  height: 33%;
  background-color: #333;
  float: left;
  -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
          animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}
.sk-cube-grid .sk-cube1 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }
.sk-cube-grid .sk-cube2 {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s; }
.sk-cube-grid .sk-cube3 {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s; }
.sk-cube-grid .sk-cube4 {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s; }
.sk-cube-grid .sk-cube5 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }
.sk-cube-grid .sk-cube6 {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s; }
.sk-cube-grid .sk-cube7 {
  -webkit-animation-delay: 0s;
          animation-delay: 0s; }
.sk-cube-grid .sk-cube8 {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s; }
.sk-cube-grid .sk-cube9 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }

@-webkit-keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
            transform: scale3D(1, 1, 1);
  } 35% {
    -webkit-transform: scale3D(0, 0, 1);
            transform: scale3D(0, 0, 1);
  }
}

@keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
            transform: scale3D(1, 1, 1);
  } 35% {
    -webkit-transform: scale3D(0, 0, 1);
            transform: scale3D(0, 0, 1);
  }
}

.modal {
  font-family: $font-family !important;
}

.tipsy{
  font-size: 16px;
  font-family: $font-family !important;
}

.checkbox .control-label{
  margin-right: 20px;
}

.table > thead > tr > th{
  text-align: right;
  font-family: $font-family !important;
}

.sidebar-wrapper .badge{
  margin-top: 5px;
}
.badge-danger{
  background:#d9534f !important;
}

.bootform .checkbox input{
  display: block !important;
  right: 0;
}